import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs'
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class TendersOffersComparisonService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService) { }

  offersListChanged = new Subject();

  getOffersHeaders(producerId): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'tenders-offers-list', {
                params: {
                  id: producerId
                }
              })
              .toPromise()
              .then(this.responseHandlingService.handleData)
              .catch(this.responseHandlingService.handleError)
  }

  getOfferDetails(offerId): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'tenders-offers-list/offer-details', {
                  params: {
                    id: offerId
                  }
                })
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  editOffer(offerToEdit, id): Promise<any>{
    return this.http.post(this.globals.apiUrl + 'tenders-offers-list/edit', offerToEdit, {
                  params: {
                    headerId: id
                  }
                })
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  deleteOffer(offerToDelete): Promise<any>{
    return this.http.post(this.globals.apiUrl + 'tenders-offers-list/delete', offerToDelete)
               .toPromise()
               .then(() => {
                  this.responseHandlingService.handleData;
                  this.offersListChanged.next();
                })
               .catch(this.responseHandlingService.handleError);
  }

  getActiveProductByProducer(producerId): Promise<any>{
    localStorage.getItem('tendersCurrentEditProducerId');
    return this.http.get(this.globals.apiUrl + 'tenders-compare-offers', {
                  params: {
                    id: producerId
                  }
                })
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }
}
