import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { LastPurchasePriceService } from '../../../services/last-purchase-price.service';

@Component({
  selector: 'app-last-purchase-prices',
  templateUrl: './last-purchase-prices.component.html',
  styleUrls: ['./last-purchase-prices.component.scss']
})
export class LastPurchasePricesComponent implements OnInit {

  lastPurchasePriceForm: FormGroup;

  productEanCode:number;
  productName: string;
  producerName: string;
  supplierName: string;
  productNameTips: string;
  onlyLastPurchase: boolean = false;

  searchFormValues =[];

  producersList = [];
  suppliersList = [];
  currentDisplayedProductName = [];
  lastItemPurchaseData = null;
  
  constructor(private lastPurchasePriceService: LastPurchasePriceService) {}

  

  ngOnInit() {
    this.initForm();
    this.getDataToSelectElements();
    this.formControlValuesChanged();
  }

  async getDataToSelectElements() {
    this.producersList = await this.lastPurchasePriceService.getProducersList();
    this.suppliersList = await this.lastPurchasePriceService.getSuppliersList();
  }

  private async initForm() {
    this.lastPurchasePriceForm = new FormGroup({
      'productEanCode': new FormControl(this.productEanCode),
      'productName': new FormControl(this.productName),
      'producerName': new FormControl(this.producerName),
      'supplierName': new FormControl(this.supplierName),
      'currentDisplayedProductNameTips': new FormControl(this.productNameTips)
    });
    this.setNameInputValidation();
    this.setEanInputValidation();
  }

  async onSubmit() {
    await this.lastPurchasePriceService.searchForProductData(this.lastPurchasePriceForm.value).
      then(async (data) => {
      if(!this.onlyLastPurchase) this.lastItemPurchaseData = data;
      else this.lastItemPurchaseData = this.getUnique(data, "Nazwasklepu");
      this.currentDisplayedProductName = await this.lastPurchasePriceService.getCurrentDisplayedProductName(this.lastItemPurchaseData);
    });
  }

  private getUnique(arr, comp) {
    const unique = arr
         .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
     return unique;
  }

  private onlyLastPurchaseToggle(e) {
    if(e == true) {
      this.onlyLastPurchase = true;
    } else {
      this.onlyLastPurchase = false;
    }
  }

  onClear() {
    this.lastPurchasePriceForm.reset();
    this.setEanInputValidation();
    this.setNameInputValidation();
  }

  formControlValuesChanged() {
    this.lastPurchasePriceForm.valueChanges.subscribe(
        (formValues) => {
          this.searchFormValues = formValues;
        }
    );
  }

  eanKeyPress() {
    if(this.lastPurchasePriceForm.get('productEanCode').valid) this.removeNameInputValidation();
    else this.setNameInputValidation();
  }

  nameKeyPress() {
    if(this.lastPurchasePriceForm.get('productName').valid) this.removeEanInputValidation();
    else this.setEanInputValidation();
  }

  ngSelectChange() {
    this.setProducerAndSupplierInputValidation();
  }

  setProducerAndSupplierInputValidation() {
    if(this.searchFormValues['producerName'] != null
      || this.searchFormValues['supplierName'] != null) {
        this.removeEanInputValidation();
        this.removeNameInputValidation();
      } else {
        if(this.searchFormValues['productName'] == null && this.searchFormValues['productEanCode'] == null) {
          this.setEanInputValidation();
          this.setNameInputValidation();
        }
    }
  }
  
  productNameTipsRemove() {
    if(this.lastPurchasePriceForm.valid) {
      this.onSubmit()
    } else {
      return false;
    }

  }

  removeNameInputValidation() {
    this.lastPurchasePriceForm.get('productName').clearValidators();
    this.lastPurchasePriceForm.get('productName').updateValueAndValidity();
  }

  removeEanInputValidation() {
    this.lastPurchasePriceForm.get('productEanCode').clearValidators();
    this.lastPurchasePriceForm.get('productEanCode').updateValueAndValidity();
  }

  setNameInputValidation() {
    this.lastPurchasePriceForm.get('productName').setValidators([
      Validators.required,
      Validators.minLength(5)
    ]);
    this.lastPurchasePriceForm.get('productName').updateValueAndValidity();
  }

  setEanInputValidation() {
    this.lastPurchasePriceForm.get('productEanCode').setValidators([
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(13),
      Validators.pattern(/^[1-9]+[0-9]*$/)
    ]);
    this.lastPurchasePriceForm.get('productEanCode').updateValueAndValidity();
  }

}


