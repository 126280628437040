import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { TendersManageContractorsService } from 'src/app/services/tenders-manage-contractors.service';

@Component({
  selector: 'app-manage-tenders-participant-edit',
  templateUrl: './manage-tenders-participant-edit.component.html',
  styleUrls: ['./manage-tenders-participant-edit.component.scss']
})
export class ManageTendersParticipantEditComponent implements OnInit {

  editForm: FormGroup;
  loaded: boolean = true;
  editMode: string = 'new';

  name: string;
  NIP: string;
  active: boolean;
  isSupplier: boolean;
  isProducer: boolean;
  checkDigit: string;
  ID: number;

  contractorDataToEdit;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tendersManageContractorsService: TendersManageContractorsService,
              private dataSharedService: DataSharedService) { }

  ngOnInit() {
    this.editMode = this.router.url.split('/').pop();
    if(this.editMode == 'edit') {
      this.contractorDataToEdit = this.dataSharedService.getSelectedItem();
      this.ID = this.contractorDataToEdit.id;
      this.name = this.contractorDataToEdit.contractorName;
      this.NIP = this.contractorDataToEdit.contractorNIP;
      this.active = this.contractorDataToEdit.active;
      this.isSupplier = this.contractorDataToEdit.isSupplier;
      this.isProducer = this.contractorDataToEdit.isProducer;
      this.checkDigit = this.contractorDataToEdit.checkDigit;
    }
    this.initForm();
  }

  async onSubmit() {
    let data = Object.defineProperty(this.editForm.value, 'id', {
      value: this.ID,
      enumerable: true,
      configurable: true,
      writable: true
    })
    if(this.editMode == 'edit') {
      await this.tendersManageContractorsService.editContractor(data);
    } else {
      await this.tendersManageContractorsService.addContractor(data);
    }
    this.onCancel();    
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.editForm = new FormGroup({
      'name': new FormControl(this.name, Validators.compose([Validators.maxLength(255), Validators.required])),
      'nip': new FormControl(this.NIP, Validators.compose([Validators.minLength(10), Validators.maxLength(10), Validators.required])),
      'active': new FormControl(this.active, Validators.required),
      'isSupplier': new FormControl(this.active, Validators.required),
      'isProducer': new FormControl(this.active, Validators.required),
      'checkDigit': new FormControl(this.checkDigit, Validators.required),
    });
    this.loaded = true;
  }

}
