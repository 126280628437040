import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseHandlingService {

  constructor() { }

  public handleData(res: any) {
    let body = res;
    //let body = res.json();
    //console.log(body); // for development purposes only
    return body || {};
  }

  public handleError(error: any): Promise<any> {
    //console.error('An error occurred', error); // for development purposes only
    return Promise.reject(error.message || error);
  }

}
