import { Component, OnInit} from '@angular/core';
import { CentralWarehouseOrdersService } from '../../../services/central-warehouse-orders.service'
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import 'rxjs/add/operator/map'

@Component({
  selector: 'app-central-warehouse-orders',
  templateUrl: './central-warehouse-orders.component.html',
  styleUrls: ['./central-warehouse-orders.component.scss']
})
export class CentralWarehouseOrdersComponent implements OnInit {
  productAndSalesMergedData = null;
  orderWasSent: boolean = false;

  constructor(private centralWarehouseOrdersService: CentralWarehouseOrdersService) { }

  ngOnInit() {
    this.mergedProductsAndSalesData();
  }

  private getCentralWarehouseProductsData() {
    return this.centralWarehouseOrdersService.getAvailableProducts();
  }

  private getSalesData() {
    return this.centralWarehouseOrdersService.getOutletSalesData({username: localStorage.getItem('username')});
  }

  private mergedProductsAndSalesData() {
    forkJoin(
      this.getCentralWarehouseProductsData(),
      this.getSalesData()
    ).subscribe(sub => {
      this.productAndSalesMergedData = sub[0].map(val => {
      return Object.assign({}, val, sub[1].filter(v => v.Kodpaskowy === val.Kodpaskowy)[0]);
      });
    })
  }

  private orderSent() {
    this.mergedProductsAndSalesData();
    this.orderWasSent = true;
  }

  private closeAlert() {
    this.orderWasSent = false;
  }

}