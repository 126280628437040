import { Component, OnInit } from '@angular/core';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { TendersOffersComparisonService } from 'src/app/services/tenders-offers-comparison.service';
//import * as XLSX from 'xlsx-js-style';

@Component({
  selector: 'app-tender-compare-offers',
  templateUrl: './tender-compare-offers.component.html',
  styleUrls: ['./tender-compare-offers.component.scss']
})
export class TenderCompareOffersComponent implements OnInit {

  offersToCompareHeaderData;
  offersToCompareDetailsData: any = [];
  productsList;
  productsListWitPriceOffersTable = [];
  offersId;
  wbName;
  
  constructor(private tendersOffersComparisonService: TendersOffersComparisonService,
              private dataSharedService: DataSharedService) { }

  async ngOnInit() {
    this.offersToCompareHeaderData = this.dataSharedService.getSelectedItem();
    this.wbName = 'Oferta ' + this.offersToCompareHeaderData[0].producerName.toUpperCase();
    let producerId = this.offersToCompareHeaderData[this.offersToCompareHeaderData.length - 1].currentProducerId;
    this.offersToCompareHeaderData.splice(-1);
    this.offersId = this.offersToCompareHeaderData.map(({ id }) => id);
    await this.getProducerProducts(producerId);
    await this.getOffersData(this.offersId);
  }

  async getProducerProducts(producerId) {
    this.productsList = await this.tendersOffersComparisonService.getActiveProductByProducer(producerId);
  }

  async getOffersData(offersId) {
    for(let id of offersId) {
      let offerData = await this.tendersOffersComparisonService.getOfferDetails(id);
      for(let item of offerData) {
        //if(item.productPrice == 0 || item.productPrice == undefined || item.productPrice == null) item.productPrice = "b.d.";
        if(item.productPrice == 0 || item.productPrice == undefined || item.productPrice == null) item.productPrice = "—";
      }
      this.offersToCompareDetailsData.push(offerData);
    }
   this.prepareTableToDisplayData();
  }

  async prepareTableToDisplayData() {
    for(let product of this.productsList) {
      product.offersData = [];
      for(let item of this.offersToCompareDetailsData) {
        product.offersData.push(item.find(
          (o:any) => o.productCode === product.productCode
        ));
      }
      let pricesArray = product.offersData.filter(function(item) {
        if (item == undefined) return false;
        return true; //else if not undefined
      }).map(function(item) {
        return item.productPrice;
      });
      
      let min = Math.min.apply(null, pricesArray.filter(function(n) { return !isNaN(n); }));
      
      for(let prod of product.offersData.filter(function(item) {
                                                  if (item == undefined) return false;
                                                  return true; //else if not undefined
                                                })
        ){
          console.log(prod.productPrice);
          if(prod.productPrice == min) prod.htmlClass="minValue";
      }
      this.productsListWitPriceOffersTable.push(product);
    }
    console.log(this.productsListWitPriceOffersTable);
  }

  //Export with formatting
  // ***** Pierwsza wersja, nie działa export do excela *******/
  /*exportToExcel() {
  const uri = 'data:application/vnd.ms-excel;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>`;
    const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
    const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };

    const table = document.getElementById('excel-table'); 
    const ctx = { worksheet: 'Worksheet', table: table.innerHTML };

    const link = document.createElement('a');
    link.download = `${this.wbName}.xlsx`;
    link.href = uri + base64(format(template, ctx));
    link.click();
  }*/

  // **************** Działa export do excela, ale ceny exportują sie jako data ******************************//
  /*exportToExcel = (function() {
    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
      , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    return function(table, name) {
      table = document.getElementById(table);
      var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML};
      window.location.href = uri + base64(format(template, ctx))
    }
  })()*/

  exportToExcel = (function () {
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        }, format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            })
        }
    return function (table, name, filename) {
        if (!table.nodeType) table = document.getElementById('excel-table');
        var ctx = {
            worksheet: name || 'Worksheet',
            table: table.innerHTML
        }
        const link = document.createElement('a');
        link.href = uri + base64(format(template, ctx));
        link.download = filename;
        link.click();

    }
})();

download(){
    var name = this.wbName;
    this.exportToExcel('Tabela', 'Arkusz 1', name+'.xls');
}
  
}
