import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProducersDebtsSummaryService } from 'src/app/services/producers-debts-summary.service';

@Component({
  selector: 'app-producers-summary-details',
  templateUrl: './producers-summary-details.component.html',
  styleUrls: ['./producers-summary-details.component.scss']
})
export class ProducersSummaryDetailsComponent implements OnInit {

  producerDebtDetails;
  producerName = "";
  producerDebtSummary: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private producersDebtsSummary: ProducersDebtsSummaryService) { }

  ngOnInit() {
    this.getDetailsAboutProducer();
  }

  async getDetailsAboutProducer() {
    let id = this.router.url.split('/').pop();
    this.producerDebtDetails = await this.producersDebtsSummary.getDetailsAboutProducer(id);
    this.producerName = this.producerDebtDetails['0'].producerName;
    this.producerDebtSummary =  this.producerDebtDetails.reduce(function (accumulator, curValue) {
      return accumulator + curValue.debtValue
    }, 0);
  }

  return() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

}
