import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shops-list-summary-modal',
  templateUrl: './shops-list-summary-modal.component.html',
  styleUrls: ['./shops-list-summary-modal.component.scss']
})
export class ShopsListSummaryModalComponent implements OnInit {

  @Input() debtsByShops = [];

  constructor(private activeModal: NgbActiveModal) {
    
  }

  ngOnInit() {
  }

  /*public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }*/

  public dismiss() {
    this.activeModal.dismiss();
  }
  

}
