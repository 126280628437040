import {  ElementRef,
          Renderer2, 
          Component, 
          OnInit, 
          AfterViewInit, 
          Input, 
          EventEmitter, 
          Output, 
          QueryList, 
          ViewChildren,
          HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OrderConfirmModalService } from '../order-confirm-modal/order-confirm-modal.service';
import { CentralWarehouseOrdersService } from '../../../../services/central-warehouse-orders.service';
import { UserAuthenticationService } from '../../../../services/user-authentication.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsListComponent implements OnInit, AfterViewInit {
  order_table_rows;
  centralWarehouseProducts;
  daysOrderForcecastValue: string;
  zeroValuesHidden: boolean = false;
  searchText:string = "";

  @Output() orderWasSent = new EventEmitter<{}>();

  @Input() set centralWarehouseProductsSetter(value) {
    this.centralWarehouseProducts = value;
    this.order_table_rows = [this.elem.nativeElement.querySelector('.order-table-rows')];
    this.order_table_rows = this.order_table_rows[0].children;
  };

  @ViewChildren('sourceTh')
  sourceTh: QueryList<ElementRef>;

  @ViewChildren('targetTh')
  targetTh: QueryList<ElementRef>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeColumns();
  }

  constructor(private elem: ElementRef,
              private orderConfirmModalService: OrderConfirmModalService,
              private centralWarehouseOrdersService: CentralWarehouseOrdersService,
              private userAuthenticationService: UserAuthenticationService,
              private renderer2: Renderer2) {
   
  }

  ngOnInit() {
    this.daysOrderForcecastValue = "7 dni";
  }

  ngAfterViewInit() {
    this.sourceTh.changes.subscribe(() => {
      this.resizeColumns();
    });
  }

  private resizeColumns() {
    let widths = this.sourceTh.map(th => th.nativeElement.offsetWidth);

    this.targetTh.forEach((th, index) => {
      this.renderer2.setStyle(
        th.nativeElement, 
        'width', 
        `${widths[index]}px`
      );
    });
  }

  private filterAction() {
    this.filterByName(this.searchText);
    if(this.zeroValuesHidden) this.hideZeroValuesToggle(true);
    this.resizeColumns();
  }

  private hideZeroValuesToggle(e) {
    if(e == true) {
      this.hideZeroValues();
    } else {
      this.showZeroValues();
      this.filterByName(this.searchText);
    }
    this.resizeColumns();
  }

  private setForecastAsOrderValue() {
    let iterator = 0;
    for(let item of this.order_table_rows) {
      item.children[9].children[0].value = Number.parseInt(item.children[8].textContent);
      this.centralWarehouseProducts[iterator].orderValue = Number.parseInt(item.children[9].children[0].value);
      iterator++;
    }
  }

  private clearOrderValues() {
    let iterator = 0;
    for(let item of this.order_table_rows) {
      item.children[9].children[0].value = null;
      this.centralWarehouseProducts[iterator].orderValue = null;
      iterator++;
    }
  }

  private onSubmit() {
    this.prepareOrderToSend();
  }

  private prepareOrderToSend() {
    let orderToCentralWarehouse = [];
    let i;
    for(i=0; i < this.centralWarehouseProducts.length; i++) {
      if(this.centralWarehouseProducts[i].orderValue) {
        orderToCentralWarehouse.push({
          productName: this.centralWarehouseProducts[i].Nazwa,
          productEanCode: this.centralWarehouseProducts[i].Kodpaskowy,
          productOrderValue: this.centralWarehouseProducts[i].orderValue,
        })
      }
    }
    if(orderToCentralWarehouse.length == 0) {
      alert('Nie wybrałeś żadnego produktu.')
      return;
    }
    this.orderConfirmModalService.confirm('Potwierdź zamówienie do magazynu centralnego', orderToCentralWarehouse)
    .then(async (confirmed) => {
      if(confirmed) {
        let orderData = [this.userAuthenticationService.getUsername(), orderToCentralWarehouse]
        await this.centralWarehouseOrdersService.sendOrderToCentralWarehouse(orderData).then(() => {
         this.orderWasSent.emit();
        });
        
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private onDaysOrderForecastChange(daysValue) {
    this.daysOrderForcecastValue = daysValue;
  }

  private filterByName(text) {
    for(let item of this.order_table_rows) {
      let productName = item.children[2].textContent.toLowerCase();
      item.style.display = productName.indexOf(text.toLowerCase()) === -1 ? 'none' : 'table-row';
    }
  }

  private hideZeroValues() {
    for(let item of this.order_table_rows) {
        this.zeroValuesHidden = true;
        let productForecastValue = item.children[9].children[0].value;
        if(!(productForecastValue > 0)) item.style.display = "none";
    }
  }

  private showZeroValues() {
    for(let item of this.order_table_rows) {
      this.zeroValuesHidden = false;
      item.style.display = "table-row";
    }
  }
}

interface IOrderToCentralWarehouse {
  productName: string,
  productEanCode: string,
  productOrderValue: number
}
