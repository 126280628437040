import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { ProducersDebtsOnStoreService } from 'src/app/services/producers-debts-on-store.service';

@Component({
  selector: 'app-producers-debts-on-store-edit',
  templateUrl: './producers-debts-on-store-edit.component.html',
  styleUrls: ['./producers-debts-on-store-edit.component.scss']
})
export class ProducersDebtsOnStoreEditComponent implements OnInit {

  producerEditForm: FormGroup;
  loaded: boolean = false;

  producerName: string;
  debtValue: number;
  comments: string;

  producerDataToEdit;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataSharedService: DataSharedService,
              private producersDebtsOnStoreService: ProducersDebtsOnStoreService,
              ) { }

  ngOnInit() {
    this.producerDataToEdit = this.dataSharedService.getSelectedItem();
    this.producerName = this.producerDataToEdit.producerName;
    this.debtValue = this.producerDataToEdit.debtValue;
    this.comments = this.producerDataToEdit.comments;
    this.initForm();
  }

  async onSubmit() {
    let data = Object.defineProperty(this.producerEditForm.value, 'producerName', {
      value: this.producerName,
      enumerable: true,
      configurable: true,
      writable: true
    })
    await this.producersDebtsOnStoreService.editProducer(data);
    this.onCancel();  
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.producerEditForm = new FormGroup({
      'debtValue': new FormControl(this.debtValue, Validators.compose([Validators.pattern("^[0-9\.]*$")])),
      'comments': new FormControl(this.comments, Validators.compose([Validators.maxLength(255)]))
    });
    this.loaded = true;
  }

}
