import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs'
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class TendersManageContractorsService {

  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService) {}

  producersListChanged = new Subject();

  getContractors(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'tenders-manage-contractors')
              .toPromise()
              .then(this.responseHandlingService.handleData)
              .catch(this.responseHandlingService.handleError)
  }

  getProducers(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'tenders-manage-contractors/producers')
              .toPromise()
              .then(this.responseHandlingService.handleData)
              .catch(this.responseHandlingService.handleError)
  }

  addContractor(contractorToAdded) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-contractors', contractorToAdded)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.producersListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  editContractor(producerToEdit) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-contractors/edit', producerToEdit)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.producersListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteProducer(producerToDelete) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-contractors/delete', producerToDelete)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.producersListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }
}
