import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { TendersManageProductsService } from 'src/app/services/tenders-manage-products.service';

@Component({
  selector: 'app-producer-products-edit',
  templateUrl: './producer-products-edit.component.html',
  styleUrls: ['./producer-products-edit.component.scss']
})
export class ProducerProductsEditComponent implements OnInit {

  editForm: FormGroup;
  loaded: boolean = true;
  editMode: string = 'new';

  name: string;
  code: string;
  producerName: string;
  active: boolean;
  isPrimary: boolean;
  ID: number;

  productDataToEdit;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private tendersManageProductsService: TendersManageProductsService,
    private dataSharedService: DataSharedService) { }

  ngOnInit() {
    this.editMode = this.router.url.split('/').pop();
    if(this.editMode == 'edit') {
      this.productDataToEdit = this.dataSharedService.getSelectedItem();
      this.ID = this.productDataToEdit.id;
      this.code = this.productDataToEdit.productCode;
      this.name = this.productDataToEdit.productName;
      this.active = this.productDataToEdit.active;
      this.isPrimary = this.productDataToEdit.isPrimary;
    }
    this.initForm();
  }

  async onSubmit() {
    let data = Object.defineProperty(this.editForm.value, 'id', {
      value: this.ID,
      enumerable: true,
      configurable: true,
      writable: true
    });
    data.producerId = Number(localStorage.getItem('tendersCurrentEditProducerId'));
    if(this.editMode == 'edit') {
      await this.tendersManageProductsService.editProduct(data);
    } else {
      await this.tendersManageProductsService.addProduct(data);
    }
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.editForm = new FormGroup({
      'name': new FormControl(this.name, Validators.compose([Validators.maxLength(255), Validators.required])),
      'code': new FormControl(this.code, Validators.compose([Validators.minLength(13), Validators.maxLength(13), Validators.required])),
      'active': new FormControl(this.active, Validators.required),
      'isPrimary': new FormControl(this.isPrimary, Validators.required),
      });
    this.loaded = true;
  }

}
