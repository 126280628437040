import { Component, OnInit } from '@angular/core';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-manage-producers',
  templateUrl: './manage-producers.component.html',
  styleUrls: ['./manage-producers.component.scss']
})
export class ManageProducersComponent implements OnInit {

  constructor(private dataSharedService: DataSharedService) { }

  ngOnInit() {
  }

  producerSelected(data) {
    this.dataSharedService.selectedItem = data;
  }

}
