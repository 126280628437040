import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { TendersOffersComparisonService } from 'src/app/services/tenders-offers-comparison.service';
import { TenderOfferDetailsModalService } from 'src/app/services/tender-offer-details-modal.service';
import { Subscription } from 'rxjs/Subscription';
import { ManageProducersService } from 'src/app/services/manage-producers.service';
import { TendersManageContractorsService } from 'src/app/services/tenders-manage-contractors.service';
import { DeleteItemModalService } from 'src/app/services/delete-item-model.service';

@Component({
  selector: 'app-tender-offers-list',
  templateUrl: './tender-offers-list.component.html',
  styleUrls: ['./tender-offers-list.component.scss']
})
export class TenderOffersListComponent implements OnInit {

  offersList;
  offersListTable;
  subscription: Subscription;
  checkedElementsTable: any = [];
  producersList;
  selectedProducer: any;
  currentSelectedProducerId;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private tendersOffersComparisonService: TendersOffersComparisonService,
    private tenderOfferDetailsModalService: TenderOfferDetailsModalService,
    private dataSharedService: DataSharedService,
    private tendersService: TendersManageContractorsService,
    private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.getProducersList();
    this.subscription = this.tendersOffersComparisonService.offersListChanged.subscribe(() => {
      this.getOffers(this.currentSelectedProducerId);
    });
  }

  producerOnChange(newValue: string) {
    this.selectedProducer = newValue;
    this.currentSelectedProducerId = this.producersList.find(
      (o:any) => o.contractorName === this.selectedProducer
    );
    this.currentSelectedProducerId = this.currentSelectedProducerId.id;
    this.getOffers(this.currentSelectedProducerId);
  }

  async getOffers(producerId) {
    this.offersList = await this.tendersOffersComparisonService.getOffersHeaders(producerId);
    this.offersListTable = this.offersList;
  }

  async getProducersList() {
    this.producersList = await this.tendersService.getProducers();
  }

  showOfferDetailsModal(offer) {
    this.tenderOfferDetailsModalService.confirm(offer.id, 'Szczegóły oferty od ' + offer.contractorName + ' na produkty ' + offer.producerName + ' obowiązująca w okresie ' + offer.month + " " + offer.year, '')
    .then((confirmed) => {

    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  compareSelectedOffers() {
    this.checkedElementsTable.push({currentProducerId: this.currentSelectedProducerId })
    this.dataSharedService.selectedItem = this.checkedElementsTable;
    this.router.navigate(['../compare-offers'], {relativeTo: this.route});
  }

  onEditOffer(offerHeaderData) {
    this.dataSharedService.selectedItem = offerHeaderData;
    this.router.navigate(['../edit-offer'], {relativeTo: this.route});
  }

  onDeleteOffer(offer) {
    this.deleteItemModalService.confirm('Usuwasz ofertę od kontrahenta', 'Czy na pewno chcesz usunąć ofertę od dostawcy "' + offer.contractorName + '" na producenta "' + offer.producerName + '" obowiązującą w okresie "' + offer.month + " " + offer.year + " ?")
    .then((confirmed) => {
      if(confirmed) {
        this.tendersOffersComparisonService.deleteOffer(offer);
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  checkedElement(event, element) {
    if(event.target.checked) {
      this.checkedElementsTable.push(element)
    } else {
      const index: number = this.checkedElementsTable.indexOf(element);
      if (index !== -1) {
          this.checkedElementsTable.splice(index, 1);
      }  
    }
  }


}
