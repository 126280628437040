import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DetailStarterComponent } from "./components/panel-container/detail-starter/detail-starter.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { PanelContainerComponent } from "./components/panel-container/panel-container.component";

import { AuthGuard } from './helpers/auth.guard';
import { LastPurchasePricesComponent } from "./components/panel-container/last-purchase-prices/last-purchase-prices.component";
import { CentralWarehouseOrdersComponent } from "./components/panel-container/central-warehouse-orders/central-warehouse-orders.component";
import { ProducersDebtsOnStoreComponent } from "./components/panel-container/producers-debts/producers-debts-on-store/producers-debts-on-store.component";
import { ProducersDebtsOnStoreEditComponent } from "./components/panel-container/producers-debts/producers-debts-on-store/producers-debts-on-store-edit/producers-debts-on-store-edit.component";
import { ProducersDebtsSummaryComponent } from "./components/panel-container/producers-debts/producers-debts-summary/producers-debts-summary.component";
import { ProducersListSummaryComponent } from "./components/panel-container/producers-debts/producers-debts-summary/producers-list-summary/producers-list-summary.component";
import { ProducersSummaryDetailsComponent } from "./components/panel-container/producers-debts/producers-debts-summary/producers-summary-details/producers-summary-details.component";
import { ManageProducersComponent } from "./components/panel-container/producers-debts/manage-producers/manage-producers.component";
import { ManageProducersEditComponent } from "./components/panel-container/producers-debts/manage-producers/manage-producers-edit/manage-producers-edit.component";
import { TendersComponent } from "./components/panel-container/tenders/tenders.component";
import { ContractorManagementListComponent } from "./components/panel-container/tenders/contractor-management-list/contractor-management-list.component";
import { ManageTendersParticipantEditComponent } from "./components/panel-container/tenders/manage-tenders-participant-edit/manage-tenders-participant-edit.component";
import { ManageTendersProductComponent } from "./components/panel-container/tenders/manage-tenders-product/manage-tenders-product.component";
import { ProducerProductsComponent } from "./components/panel-container/tenders/manage-tenders-product/producer-products/producer-products.component";
import { ProducerProductsEditComponent } from "./components/panel-container/tenders/manage-tenders-product/producer-products-edit/producer-products-edit.component";
import { TendersProducersListComponent } from "./components/panel-container/tenders/manage-tenders-product/tenders-producers-list/tenders-producers-list.component";
import { TenderOffersListComponent } from "./components/panel-container/tenders/tender-offers-list/tender-offers-list.component";
import { TenderCompareOffersComponent  } from "./components/panel-container/tenders/tender-compare-offers/tender-compare-offers.component";
import { TenderOfferEditComponent } from "./components/panel-container/tenders/tender-offer-edit/tender-offer-edit.component";
import { Role } from "./models/role.model";

const appRoutes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full'},
    { path: 'panel', component: PanelContainerComponent, canActivate: [AuthGuard], children: [
        { path: 'last-prices', component: LastPurchasePricesComponent, canActivate: [AuthGuard]},
        { path: 'central-warehouse-order', component: CentralWarehouseOrdersComponent, canActivate: [AuthGuard]},
        { path: 'producers-debts-on-store', component: ProducersDebtsOnStoreComponent, canActivate: [AuthGuard], children: [
            { path: 'edit', component: ProducersDebtsOnStoreEditComponent  }
        ]},
        { path: 'producers-debts-summary', component: ProducersDebtsSummaryComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: '', component: ProducersListSummaryComponent },
            { path: ':id', component: ProducersSummaryDetailsComponent }
        ]},
        { path: 'manage-producers', component: ManageProducersComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: 'new', component: ManageProducersEditComponent },
            { path: 'edit', component: ManageProducersEditComponent }
        ]},
        { path: 'tenders', component: TendersComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }, children: [
            { path: 'offers-list', component: TenderOffersListComponent },
            { path: 'edit-offer', component: TenderOfferEditComponent },
            { path: 'compare-offers', component: TenderCompareOffersComponent},
            { path: 'contractors', component: ContractorManagementListComponent },
            { path: 'contractors/new', component: ManageTendersParticipantEditComponent },
            { path: 'contractors/edit', component: ManageTendersParticipantEditComponent },
            { path: 'manage-products', component: ManageTendersProductComponent, children: [
                { path: 'products', component: ProducerProductsComponent },
                { path: 'products/new', component: ProducerProductsEditComponent },
                { path: 'products/edit', component: ProducerProductsEditComponent },
                { path: 'producers', component: TendersProducersListComponent }
            ]},
            
        ] }
    ] },
    { path: 'login', component: SignInComponent}
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}