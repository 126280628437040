import { Component, OnInit, Input } from '@angular/core';
import { async } from '@angular/core/testing';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TendersOffersComparisonService } from 'src/app/services/tenders-offers-comparison.service';

@Component({
  selector: 'app-tender-offer-details-modal',
  templateUrl: './tender-offer-details-modal.component.html',
  styleUrls: ['./tender-offer-details-modal.component.scss']
})
export class TenderOfferDetailsModalComponent implements OnInit {

  offerDetails;
  offerDetailsTable;
  wbName;

  @Input() id;
  @Input() title: string;
  @Input() peroid: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal,
              private tendersOffersComparisonService: TendersOffersComparisonService) { }

  ngOnInit() {
    this.getOfferDetails(this.id);
  }

  async getOfferDetails(id) {
    this.offerDetails = await this.tendersOffersComparisonService.getOfferDetails(id);
    this.offerDetailsTable = this.offerDetails;
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  exportToExcel = (function () {
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        }, format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            })
        }
    return function (table, name, filename) {
        if (!table.nodeType) table = document.getElementById('excel-table');
        var ctx = {
            worksheet: name || 'Worksheet',
            table: table.innerHTML
        }
        const link = document.createElement('a');
        link.href = uri + base64(format(template, ctx));
        link.download = filename;
        link.click();

    }
})();

download(){
    var name = this.wbName;
    this.exportToExcel('Tabela', 'Arkusz 1', this.title+'.xls');
}

}
