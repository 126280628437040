import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-item-modal',
  templateUrl: './order-confirm-modal.component.html',
  styleUrls: ['./order-confirm-modal.component.scss']
})
export class OrderConfirmModalComponent implements OnInit {
  
  @Input() title: string;
  @Input() order = [];
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) {
    
  }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
  

}
