import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-last-purchase-prices-result-table',
  templateUrl: './last-purchase-prices-result-table.component.html',
  styleUrls: ['./last-purchase-prices-result-table.component.scss']
})
export class LastPurchasePricesResultTableComponent implements OnInit {

  @Input() itemsPurchaseData;

  constructor() { }

  ngOnInit() {
  
  }

}
