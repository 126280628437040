import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from '../services/response-handling.service';
import { UserAuthenticationService } from '../services/user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CentralWarehouseOrdersService {
  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private userAuthenticationService: UserAuthenticationService) {}

  public getAvailableProducts(): Promise<any>{
    return this.http.get(this.globals.apiUrl + 'centralWarehouse/getAvailableProducts')
           .toPromise()
           .then(this.responseHandlingService.handleData)
           .catch(this.responseHandlingService.handleError)
  }

  public getOutletSalesData(username): Promise<any>{
    return this.http.post(this.globals.apiUrl + 'centralWarehouse/getOutletSalesData', { 
      username: this.userAuthenticationService.getUsername()
    })
           .toPromise()
           .then(this.responseHandlingService.handleData)
           .catch(this.responseHandlingService.handleError)
  }

  public sendOrderToCentralWarehouse(order): Promise<any> {
    return this.http.post(this.globals.apiUrl + 'centralWarehouse/sendOrder', order)
                .toPromise()
                .then(this.responseHandlingService.handleData)
                .catch(this.responseHandlingService.handleError);
  }
}
