import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from '../services/response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class LastPurchasePriceService {

    constructor(private http: HttpClient,
                private globals: Globals,
                private responseHandlingService: ResponseHandlingService) {}

    public getProducersList(): Promise<any>{
        return this.http.get(this.globals.apiUrl + 'lastPurchasePrices/getUniqueProducersList')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
    }

    public getSuppliersList(): Promise<any>{
        return this.http.get(this.globals.apiUrl + 'lastPurchasePrices/getUniqueSuppliersList')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
    }

    public searchForProductData(searchFromData): Promise<any> {
        return this.http.post(this.globals.apiUrl + 'lastPurchasePrices/getProductData', searchFromData)
                    .toPromise()
                    .then(this.responseHandlingService.handleData)
                    .catch(this.responseHandlingService.handleError);
    }

    public getCurrentDisplayedProductName(lastItemPurchaseData) {
        let currentDisplayedProductName = [];
        lastItemPurchaseData.forEach(function(singleItemPurchaseData) {
            if(currentDisplayedProductName.indexOf(singleItemPurchaseData["Nazwa"]) == -1) currentDisplayedProductName.push(singleItemPurchaseData["Nazwa"]);
        })
        return currentDisplayedProductName;
    }
    
}