import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TendersManageContractorsService } from 'src/app/services/tenders-manage-contractors.service';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-tenders-producers-list',
  templateUrl: './tenders-producers-list.component.html',
  styleUrls: ['./tenders-producers-list.component.scss']
})
export class TendersProducersListComponent implements OnInit {

  producersList;
  producersListTable;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tendersService: TendersManageContractorsService,
              private dataSharedService: DataSharedService) { }

  ngOnInit() {
    this.getContractors();
  }

  async getContractors() {
    this.producersList = await this.tendersService.getProducers();
    this.producersListTable = this.producersList;
  }

  onEditProducts(producer) {
    this.dataSharedService.selectedItem = producer.contractorName;
    localStorage.setItem('tendersCurrentEditProducerId', producer.id);
    this.router.navigate(['../products'], {relativeTo: this.route});
  }

}
