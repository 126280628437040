import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-confirmation-alert',
  templateUrl: './order-confirmation-alert.component.html',
  styleUrls: ['./order-confirmation-alert.component.scss']
})
export class OrderConfirmationAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
