import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharedService {

  selectedItem = null;

   getSelectedItem() {
    return this.selectedItem;
   }



}
