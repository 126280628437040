import { Component, OnInit } from '@angular/core';
import { TendersOffersComparisonService } from 'src/app/services/tenders-offers-comparison.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { FormGroup, FormControl,FormArray, FormBuilder, ReactiveFormsModule, Validators, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-tender-offer-edit',
  templateUrl: './tender-offer-edit.component.html',
  styleUrls: ['./tender-offer-edit.component.scss']
})
export class TenderOfferEditComponent implements OnInit {
  
  productsForm: FormGroup;
	productsList: any;

  offerHeaderData;
  offer;
  id;

  constructor(private tendersOffersComparisonService: TendersOffersComparisonService,
              private dataSharedService: DataSharedService,
              private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute ) {
                this.productsForm = this.fb.group({
                  products: this.fb.array([]),
                  });
                }

  ngOnInit() {
    this.offerHeaderData = this.dataSharedService.getSelectedItem();
    console.log(this.offerHeaderData);
    this.getOfferDetails(this.offerHeaderData.id);
  }

  async getOfferDetails(id) {
    this.productsList = await this.tendersOffersComparisonService.getOfferDetails(id);
    for(let product of this.productsList) {
			this.addProductToForm(product.productCode, product.productName, product.productPrice, product.productAdditionalInfo);
		}
  }

  addProductToForm(productCodeVal:string, productNameVal:string, productPriceVal:number, productAdditionalInfoVal:string) {
		const item = this.productsForm.controls['products'] as FormArray;
		item.push(this.fb.group({
		  productCode: productCodeVal,
		  productName: productNameVal,
		  productPrice: productPriceVal,
		  productAdditionalInfo: productAdditionalInfoVal
		}));
	}

  async submit() {
		this.editOfferData();
	}

  async editOfferData() {
    for(let item of (this.productsForm.get("products") as FormArray)['controls']) {
      if(item.value.productPrice == "") item.value.productPrice = null;
      await this.tendersOffersComparisonService.editOffer(item.value, this.offerHeaderData.id);
    }
    this.onCancel();
  }

  onCancel() {
    this.router.navigate(['../offers-list'], {relativeTo: this.route});
  }

  get productsTable() : FormArray {
		return this.productsForm.get("products") as FormArray
	}

}
