import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';
import { DataSharedService } from './data-shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProducersDebtsSummaryService {
  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService,
              private dataSharedService: DataSharedService) {}

  getSumProducersDebts(): Promise<any> {
    return this.http.get(this.globals.apiUrl + 'producers-debts-summary')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

  getSumShopsDebts(): Promise<any> {
    return this.http.get(this.globals.apiUrl + 'producers-debts-summary/shops')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

  getDetailsAboutProducer(id): Promise<any> {
    return this.http.get(this.globals.apiUrl + 'producers-debts-summary/' + id)
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }

}