import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TendersManageContractorsService } from 'src/app/services/tenders-manage-contractors.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { DeleteItemModalService } from 'src/app/services/delete-item-model.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-contractor-management-list',
  templateUrl: './contractor-management-list.component.html',
  styleUrls: ['./contractor-management-list.component.scss']
})
export class ContractorManagementListComponent implements OnInit {

  contractorsList;
  contractorsListTable;
  subscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tendersManageContractorsService: TendersManageContractorsService,
              private dataSharedService: DataSharedService,
              private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.getContractors();
    this.subscription = this.tendersManageContractorsService.producersListChanged.subscribe(() => {
      this.getContractors();
    });
  }

  async getContractors() {
    this.contractorsList = await this.tendersManageContractorsService.getContractors();
    this.contractorsListTable = this.contractorsList;
  }

  onNewContractor() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

  onEditItem(contractor) {
    this.dataSharedService.selectedItem = contractor;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteItem(contractor) {
    this.deleteItemModalService.confirm('Usuwasz kontrahenta', 'Czy na pewno chcesz usunąc kontrahenta: "' + contractor.contractorName + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.tendersManageContractorsService.deleteProducer(contractor);
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
