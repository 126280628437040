import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-tenders-product',
  templateUrl: './manage-tenders-product.component.html',
  styleUrls: ['./manage-tenders-product.component.scss']
})
export class ManageTendersProductComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.router.navigate(['producers'], {relativeTo: this.route});
  }

}
