import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs'
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class TendersManageProductsService {

  constructor(private http: HttpClient,
    private globals: Globals,
    private responseHandlingService: ResponseHandlingService) {}

    productsListChanged = new Subject();

  getProductByProducer(): Promise<any>{
    localStorage.getItem('tendersCurrentEditProducerId');
    return this.http.get(this.globals.apiUrl + 'tenders-manage-products', {
                  params: {
                    currentProducerId: localStorage.getItem('tendersCurrentEditProducerId')
                  }
                })
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError);
  }

  addProduct(productToAdded) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-products', productToAdded)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.productsListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  editProduct(productToEdit) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-products/edit', productToEdit)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.productsListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  deleteProduct(productToDelete) {
    return this.http.post(this.globals.apiUrl + 'tenders-manage-products/delete', productToDelete)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.productsListChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

}
