import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderConfirmModalComponent } from './order-confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmModalService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    order,
    btnOkText: string = 'Zamawiam',
    btnCancelText: string = 'Powrót',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(OrderConfirmModalComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.order = order;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}