import { Component, OnInit } from '@angular/core';
import { ProducersDebtsSummaryService } from 'src/app/services/producers-debts-summary.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShopsListSummaryModalComponent } from '../shops-list-summary-modal/shops-list-summary-modal.component';

@Component({
  selector: 'app-producers-list-summary',
  templateUrl: './producers-list-summary.component.html',
  styleUrls: ['./producers-list-summary.component.scss']
})
export class ProducersListSummaryComponent implements OnInit {

  producersList;
  producersListTable;
  producersDebtSummary: number;
  debtsSummaryByShops;

  constructor(private producersDebtsSummary: ProducersDebtsSummaryService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.getProducersList();
  }

  async getProducersList() {
    this.producersList = await this.producersDebtsSummary.getSumProducersDebts();
    this.producersDebtSummary =  this.producersList.reduce(function (accumulator, curValue) {
      return accumulator + curValue.totalAmount;
    }, 0);
    this.producersListTable = this.producersList;
  }

  onShowDetails(producer) {
    this.router.navigate([producer.producerId], { relativeTo: this.route });
  }

  async sumOfDebtsByShops() {
    this.debtsSummaryByShops = await this.producersDebtsSummary.getSumShopsDebts();
    const modalRef = this.modalService.open(ShopsListSummaryModalComponent);
    modalRef.componentInstance.debtsByShops = this.debtsSummaryByShops;
  }

  applyFilter(filterValue: string) {
    this.producersListTable = this.producersList;
    this.producersListTable = this.producersListTable.filter((producerData) => producerData.producerName.toLowerCase().includes(filterValue.toLowerCase()));
  }

}
