import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserAuthenticationService } from '../services/user-authentication.service'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private userAuthenticationService: UserAuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let userToken = localStorage.getItem('currentUserToken');
        let userName = localStorage.getItem('usernameLoggedIn');
        if (userToken) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${userToken}`,
                    Username: userName
                }
            });
        }
        return next.handle(request).pipe(
	        tap(event => {
	          /*if (event instanceof HttpResponse) {    
	            console.log(" all looks good");
	          }*/
	        }, error => {
                if (error.status == 419) {
                    alert('Sesja wygasła. Aby dokonywać zmian musisz ponownie się zalogować.');
                    this.userAuthenticationService.logout();
                }
	        })
	      );
    }
}