import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-producers-debts-summary',
  templateUrl: './producers-debts-summary.component.html',
  styleUrls: ['./producers-debts-summary.component.scss']
})
export class ProducersDebtsSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
