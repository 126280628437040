import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { ProducersDebtsOnStoreService } from 'src/app/services/producers-debts-on-store.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-producers-list',
  templateUrl: './producers-list.component.html',
  styleUrls: ['./producers-list.component.scss']
})
export class ProducersListComponent implements OnInit {
  
  producersList;
  producersListTable;
  subscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private producersDebtsOnStoreService: ProducersDebtsOnStoreService,
              private dataSharedService: DataSharedService,) { }

  ngOnInit() {
    this.getProducersList();
    this.subscription = this.producersDebtsOnStoreService.producersDebtsOnStoreChanged.subscribe(() => {
      this.getProducersList();
    });
  }

  async getProducersList() {
    this.producersList = await this.producersDebtsOnStoreService.getProducers();
    this.producersListTable = this.producersList;
  }

  onEditItem(producer) {
    this.dataSharedService.selectedItem = producer;
    this.router.navigate(['edit'], { relativeTo: this.route });
  }

  applyFilter(filterValue: string) {
    this.producersListTable = this.producersList;
    this.producersListTable = this.producersListTable.filter((producerData) => producerData.producerName.toLowerCase().includes(filterValue.toLowerCase()));
  }

}
