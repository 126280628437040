import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AppRoutingModule } from './app-routing.module';
import { DetailStarterComponent } from './components/panel-container/detail-starter/detail-starter.component';
import { Globals } from './globals';
import { OrderConfirmModalComponent } from './components/panel-container/central-warehouse-orders/order-confirm-modal/order-confirm-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { PanelContainerComponent } from './components/panel-container/panel-container.component';
import { JwtInterceptor} from './helpers/jwt.interceptor';
import { AuthGuard } from './helpers/auth.guard';
import { StartupService } from './services/startup.service';
import { UserAuthenticationService } from './services/user-authentication.service';
import { LastPurchasePricesComponent } from './components/panel-container/last-purchase-prices/last-purchase-prices.component';
import { LastPurchasePricesResultTableComponent } from './components/panel-container/last-purchase-prices/last-purchase-prices-result-table/last-purchase-prices-result-table.component';
import { CentralWarehouseOrdersComponent } from './components/panel-container/central-warehouse-orders/central-warehouse-orders.component';
import { ProductsListComponent } from './components/panel-container/central-warehouse-orders/products-list/products-list.component';
import { SearchByNamePipe } from './pipes/search-by-name.pipe';
import { OrderConfirmationAlertComponent } from './components/panel-container/central-warehouse-orders/order-confirmation-alert/order-confirmation-alert.component';
import { ProducersDebtsOnStoreComponent } from './components/panel-container/producers-debts/producers-debts-on-store/producers-debts-on-store.component';
import { ProducersListComponent } from './components/panel-container/producers-debts/producers-debts-on-store/producers-list/producers-list.component';
import { ProducersDebtsSummaryComponent } from './components/panel-container/producers-debts/producers-debts-summary/producers-debts-summary.component';
import { ManageProducersComponent } from './components/panel-container/producers-debts/manage-producers/manage-producers.component';
import { ProducerManagementListComponent } from './components/panel-container/producers-debts/manage-producers/producer-management-list/producer-management-list.component';
import { ManageProducersEditComponent } from './components/panel-container/producers-debts/manage-producers/manage-producers-edit/manage-producers-edit.component';
import { DeleteItemModalComponent } from './components/panel-container/delete-item-modal/delete-item-modal.component';
import { ProducersDebtsOnStoreEditComponent } from './components/panel-container/producers-debts/producers-debts-on-store/producers-debts-on-store-edit/producers-debts-on-store-edit.component';
import { ProducersListSummaryComponent } from './components/panel-container/producers-debts/producers-debts-summary/producers-list-summary/producers-list-summary.component';
import { ProducersSummaryDetailsComponent } from './components/panel-container/producers-debts/producers-debts-summary/producers-summary-details/producers-summary-details.component';
import { ShopsListSummaryModalComponent } from './components/panel-container/producers-debts/producers-debts-summary/shops-list-summary-modal/shops-list-summary-modal.component';
import { TendersComponent } from './components/panel-container/tenders/tenders.component';
import { ContractorManagementListComponent } from './components/panel-container/tenders/contractor-management-list/contractor-management-list.component';
import { ManageTendersParticipantEditComponent } from './components/panel-container/tenders/manage-tenders-participant-edit/manage-tenders-participant-edit.component';
import { ManageTendersProductComponent } from './components/panel-container/tenders/manage-tenders-product/manage-tenders-product.component';
import { ProducerProductsComponent } from './components/panel-container/tenders/manage-tenders-product/producer-products/producer-products.component';
import { TendersProducersListComponent } from './components/panel-container/tenders/manage-tenders-product/tenders-producers-list/tenders-producers-list.component';
import { ProducerProductsEditComponent } from './components/panel-container/tenders/manage-tenders-product/producer-products-edit/producer-products-edit.component';
import { TenderOffersListComponent } from './components/panel-container/tenders/tender-offers-list/tender-offers-list.component';
import { TenderOfferDetailsModalComponent } from './components/panel-container/tenders/tender-offer-details-modal/tender-offer-details-modal.component';
import { TenderCompareOffersComponent } from './components/panel-container/tenders/tender-compare-offers/tender-compare-offers.component';
import { TenderOfferEditComponent } from './components/panel-container/tenders/tender-offer-edit/tender-offer-edit.component';

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    DetailStarterComponent,
    OrderConfirmModalComponent,
    SignInComponent,
    PanelContainerComponent,
    LastPurchasePricesComponent,
    LastPurchasePricesResultTableComponent,
    CentralWarehouseOrdersComponent,
    ProductsListComponent,
    SearchByNamePipe,
    OrderConfirmationAlertComponent,
    ProducersDebtsOnStoreComponent,
    ProducersListComponent,
    ProducersDebtsSummaryComponent,
    ManageProducersComponent,
    ProducerManagementListComponent,
    ManageProducersEditComponent,
    DeleteItemModalComponent,
    ProducersDebtsOnStoreEditComponent,
    ProducersListSummaryComponent,
    ProducersSummaryDetailsComponent,
    ShopsListSummaryModalComponent,
    TendersComponent,
    ContractorManagementListComponent,
    ManageTendersParticipantEditComponent,
    ManageTendersProductComponent,
    ProducerProductsComponent,
    TendersProducersListComponent,
    ProducerProductsEditComponent,
    TenderOffersListComponent,
    TenderOfferDetailsModalComponent,
    TenderCompareOffersComponent,
    TenderOfferEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    NgHttpLoaderModule.forRoot()
  ],
  entryComponents: [
    OrderConfirmModalComponent,
    DeleteItemModalComponent,
    ShopsListSummaryModalComponent,
    TenderOfferDetailsModalComponent
  ],
  providers: [Globals,
              OrderConfirmModalComponent,
              NgbActiveModal,
              AuthGuard,
              StartupService,
              { provide: HTTP_INTERCEPTORS,
                useClass: JwtInterceptor,
                multi: true 
              },
              {
                // Provider for APP_INITIALIZER
                provide: APP_INITIALIZER,
                useFactory: startupServiceFactory,
                deps: [StartupService],
                multi: true
              },
              {
                provide: Window, 
                useValue: window
              },
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
