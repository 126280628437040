import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ManageProducersService } from 'src/app/services/manage-producers.service';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-manage-producers-edit',
  templateUrl: './manage-producers-edit.component.html',
  styleUrls: ['./manage-producers-edit.component.scss']
})
export class ManageProducersEditComponent implements OnInit {
  
  producerEditForm: FormGroup;
  loaded: boolean = false;
  editMode: string = 'new';

  producerName: string;
  producerNIP: string;
  producerID: number;

  producerDataToEdit;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private manageProducersService: ManageProducersService,
              private dataSharedService: DataSharedService) { }

  ngOnInit() {
    this.editMode = this.router.url.split('/').pop();
    if(this.editMode == 'edit') {
      this.producerDataToEdit = this.dataSharedService.getSelectedItem();
      this.producerID = this.producerDataToEdit.id;
      this.producerName = this.producerDataToEdit.producerName;
      this.producerNIP = this.producerDataToEdit.producerNIP;
    }
    this.initForm();
  }

  async onSubmit() {
    let data = Object.defineProperty(this.producerEditForm.value, 'id', {
      value: this.producerID,
      enumerable: true,
      configurable: true,
      writable: true
    })
    if(this.editMode == 'edit') {
      await this.manageProducersService.editProducer(data);
    } else {
      await this.manageProducersService.addProducer(data);
    }
    this.onCancel();    
  }
  
  onCancel() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  private async initForm() {
    this.producerEditForm = new FormGroup({
      'name': new FormControl(this.producerName, Validators.required),
      'nip': new FormControl(this.producerNIP, Validators.compose([Validators.minLength(10), Validators.maxLength(10), Validators.required]))
    });
    this.loaded = true;
  }

}
