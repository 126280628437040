import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageProducersService } from 'src/app/services/manage-producers.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { DeleteItemModalService } from 'src/app/services/delete-item-model.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-producer-management-list',
  templateUrl: './producer-management-list.component.html',
  styleUrls: ['./producer-management-list.component.scss']
})
export class ProducerManagementListComponent implements OnInit {
  
  producersList;
  producersListTable;
  subscription: Subscription;
  
  constructor(private router: Router,
              private route: ActivatedRoute,
              private manageProducersService: ManageProducersService,
              private dataSharedService: DataSharedService,
              private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.getProducersList();
    this.subscription = this.manageProducersService.producersListChanged.subscribe(() => {
      this.getProducersList();
    }
  )
  }

  async getProducersList() {
    this.producersList = await this.manageProducersService.getProducers();
    this.producersListTable = this.producersList;
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

  onEditItem(producer) {
    this.dataSharedService.selectedItem = producer;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteItem(producer) {
    this.deleteItemModalService.confirm('Usuwasz producenta!', 'Czy na pewno chcesz usunąc producenta: "' + producer.producerName + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.manageProducersService.deleteProducer(producer);
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  applyFilter(filterValue: string) {
    this.producersListTable = this.producersList;
    this.producersListTable = this.producersListTable.filter((producerData) => producerData.producerName.toLowerCase().includes(filterValue.toLowerCase()));
  }
}
