import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TendersManageProductsService } from 'src/app/services/tenders-manage-products.service';
import { Subscription } from 'rxjs/Subscription';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { DeleteItemModalService } from 'src/app/services/delete-item-model.service';

@Component({
  selector: 'app-producer-products',
  templateUrl: './producer-products.component.html',
  styleUrls: ['./producer-products.component.scss']
})
export class ProducerProductsComponent implements OnInit {

  productsList;
  productsListTable;
  subscription: Subscription;
  producerName;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tendersManageProductsService: TendersManageProductsService,
              private dataSharedService: DataSharedService,
              private deleteItemModalService: DeleteItemModalService) { }

  ngOnInit() {
    this.producerName = this.dataSharedService.getSelectedItem();
    this.subscription = this.tendersManageProductsService.productsListChanged.subscribe(() => {
      this.getProducerProducts();
    });
    this.getProducerProducts();
  }

  async getProducerProducts() {
    this.productsList = await this.tendersManageProductsService.getProductByProducer();
    this.productsListTable = this.productsList;
  }

  onNewItem() {
    this.router.navigate(['new'], {relativeTo: this.route});
  }

  onEditItem(product) {
    this.dataSharedService.selectedItem = product;
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  onDeleteItem(product) {
    this.deleteItemModalService.confirm('Usuwasz kontrahenta', 'Czy na pewno chcesz usunąć produkt: "' + product.productName + '" ?')
    .then((confirmed) => {
      if(confirmed) {
        this.tendersManageProductsService.deleteProduct(product);
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  onCancel() {
    this.router.navigate(['../producers'], {relativeTo: this.route});
  }

  applyFilter(filterValue: string) {
    this.productsListTable = this.productsList;
    this.productsListTable = this.productsListTable.filter((productData) => productData.productName.toLowerCase().includes(filterValue.toLowerCase()));
  }

}
