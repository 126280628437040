import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs'
import { Globals } from '../globals';
import { ResponseHandlingService } from './response-handling.service';

@Injectable({
  providedIn: 'root'
})
export class ProducersDebtsOnStoreService {
  constructor(private http: HttpClient,
              private globals: Globals,
              private responseHandlingService: ResponseHandlingService) {}

  producersDebtsOnStoreChanged = new Subject();

  editProducer(producerToEdit) {
    return this.http.post(this.globals.apiUrl + 'producers-debts-on-store/edit', producerToEdit)
                .toPromise()
                .then(() => {
                    this.responseHandlingService.handleData;
                    this.producersDebtsOnStoreChanged.next();
                })
                .catch(this.responseHandlingService.handleError);
  }

  getProducers(): Promise<any> {
    return this.http.get(this.globals.apiUrl + 'producers-debts-on-store')
               .toPromise()
               .then(this.responseHandlingService.handleData)
               .catch(this.responseHandlingService.handleError)
  }
}