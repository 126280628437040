import { Pipe, PipeTransform } from '@angular/core';
//import { Teacher } from './teacher';
 
@Pipe({ name: 'searchByName' })
export class SearchByNamePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    /*items.filter( it => {
        console.log(it);
      //return it.Nazwa.toLowerCase().includes(searchText);
      //it.style.display = it.Nazwa.indexOf(searchText) === -1 ? 'none' : 'table-row';
    });*/
   }
}

/*function _filter(row) {
    var text = row.textContent.toLowerCase(), val = _input.value.toLowerCase();
    row.style.display = text.indexOf(val) === -1 ? 'none' : 'table-row';
}*/

/*
if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      return it.Nazwa.toLowerCase().includes(searchText);
    });
   }*/